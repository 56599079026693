import React from "react"
import {
  FaLinkedin,
  FaGithubSquare,
  FaWhatsappSquare,
  FaEnvelopeSquare,
  FaPhoneSquare,
} from "react-icons/fa"
import { IconContext } from "react-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./contatti.scss"
const Contatti = () => (
  <Layout>
    <main className="contatti-main">
    <SEO title="Contatti" />

    <div className="contacts-text-container">
      <h1 className="main-title">Contattami!</h1>
      <p>
        Chiamami e fissiamo una prima consulenza o, se preferisci, scrivimi un
        messaggio WhatsApp, LinkedIn o una e-mail
      </p>
    </div>
    <div className="contacts-container">
     
     <a href="https://github.com/E-PL">
      <div className="contact-container">
        <div className="icon-container">
          <IconContext.Provider
            value={{ color: "#92D89D", size: "48px", className: "icon" }}
          >
            <div>
              <FaGithubSquare />
            </div>
          </IconContext.Provider>
        </div>
        <div className="contact-text">
          <p>github.com/E-PL</p>
        </div>
      </div>
      </a>

      <a href="https://www.linkedin.com/in/e-p-l/">
      <div className="contact-container">
        <div className="icon-container">
          <IconContext.Provider
            value={{ color: "#92D89D", size: "48px", className: "icon" }}
          >
            <div>
              <FaLinkedin />
            </div>
          </IconContext.Provider>
        </div>
        <div className="contact-text">
          <p>linkedin.com/e-pl</p>
        </div>
      </div>
      </a>

      <a href="mailto:info@eugeniopilastroluca.it">
      <div className="contact-container">
        <div className="icon-container">
          <IconContext.Provider
            value={{ color: "#92D89D", size: "48px", className: "icon" }}
          >
            <div>
              <FaEnvelopeSquare />
            </div>
          </IconContext.Provider>
        </div>
        <div className="contact-text">
          <p>info@eugeniopilastroluca.it</p>
        </div>
      </div>
      </a>

      
      <div className="contact-phone-container">
      <a href="tel:+393713091037">
        <div className="icon-container">
          <IconContext.Provider
            value={{ color: "#92D89D", size: "48px", className: "icon" }}
          >
            <div>
              <FaPhoneSquare />
            </div>
          </IconContext.Provider>
        </div>
        <div className="contact-text">
          <p>371 309 103 7</p>
        </div>
        </a>
        <a href="https://wa.me/393713091037">
        <div className="icon-container wa-icon-container">
          <IconContext.Provider
            value={{ color: "#92D89D", size: "48px", className: "icon" }}
          >
            <div>
              <FaWhatsappSquare />
            </div>
          </IconContext.Provider>
        </div>
        </a>
      </div>
    </div>
    </main>
  </Layout>
)

export default Contatti
